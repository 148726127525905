import React from 'react';
import * as dateFnsLocales from 'date-fns/locale';
import { useRouter } from 'next/router';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import toDate from 'date-fns/toDate';

const useDateFnsLocale = () => {
  const { locale, defaultLocale } = useRouter();
  return dateFnsLocales[locale.replace('-', '') || defaultLocale.replace('-', '')];
};

const formatDistanceLocale = {
  lessThanXSeconds: 'just now',
  xSeconds: 'just now',
  halfAMinute: 'just now',
  lessThanXMinutes: '{{count}} minutes ago',
  xMinutes: '{{count}} minutes ago',
  aboutXHours: '{{count}} hours ago',
  xHours: '{{count}} hours ago',
  xDays: '{{count}} days ago',
  aboutXWeeks: '{{count}} weeks ago',
  xWeeks: '{{count}} weeks ago',
  aboutXMonths: '{{count}} weeks ago',
  xMonths: '{{count}} months ago',
  aboutXYears: '{{count}} years ago',
  xYears: '{{count}} years ago',
  overXYears: '{{count}} years ago',
  almostXYears: '{{count}} years ago',
};

const formatDistance = (token, count, options = {}) => {
  const result = formatDistanceLocale[token]
    .replace('{{count}}', count)
    .replace('minutes', count === 1 ? 'minute' : 'minutes')
    .replace('hours', count === 1 ? 'hour' : 'hours')
    .replace('days', count === 1 ? 'day' : 'days')
    .replace('weeks', count === 1 ? 'week' : 'weeks')
    .replace('months', count === 1 ? 'month' : 'months')
    .replace('years', count === 1 ? 'year' : 'years');

  // @ts-ignore
  if (options.addSuffix) {
  // @ts-ignore
    if (options.comparison > 0) {
      return `in ${result}`;
    }
    return `${result}`;
  }

  return result;
};

export const calendar = (date: Date, locale?: Locale) => date && formatDistanceToNowStrict(toDate(date), {
  addSuffix: false,
  locale: {
    ...locale,
    formatDistance,
  },
});

const useTimeAgo = (date: Date) => {
  const locale = useDateFnsLocale();
  const [value, setValue] = React.useState(date && calendar(date, locale));
  const interval = React.useRef<number>();
  React.useEffect(() => {
    interval.current = window.setInterval(() => setValue(calendar(date, locale)), 60000);
    return () => interval.current && window.clearInterval(interval.current);
  }, [date, locale]);
  return value;
};

export default useTimeAgo;
